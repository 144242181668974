import React from "react"
import instagram from "../../../src/images/Service/instagram1.svg"
import checkmark from "../../../src/images/Service/check.svg"
import arrow from "../../../src/images/Service/arrow.svg"
import { Button } from "react-bootstrap"
import { Link } from "gatsby"

import "./ourservice.css"

const InstagramAds = ({ data}) => (
  <div className="our-service-ads-content" id="instagramads">
    <div className="container">
      <div className="our-service-ads">
        <div className="our-service-ads-text">
          <h4>instagram Ads</h4>
          <div className="our-service-ads-grid">
            {
              data?.service_point?.map((point) => {
                return (

                  <div className="our-service-ads-grid-box">
                    <img src={checkmark} alt="checkmark" />
                    <div className="our-service-ads-item-text">
                      <p>
                        <span>{point?.title}:</span>
                        {point?.description}
                      </p>
                    </div>
                  </div>
                )
              })
            }

            {/* <div className="our-service-ads-grid-box">
              <img src={checkmark} alt="checkmark" />
              <div className="our-service-ads-item-text">
                <p>
                  <span>Targeting options:</span> Google allows you to target
                  ads based on keywords, which ensures that your ad reaches the
                  right people. You can also use negative keywords to exclude
                  people who are not interested in your product or service.
                </p>
              </div>
            </div>
            <div className="our-service-ads-grid-box">
              <img src={checkmark} alt="checkmark" />
              <div className="our-service-ads-item-text">
                <p>
                  <span>Greater control:</span> With Google Ads, you have more
                  control over when and where your ad is shown. You can also set
                  a budget for how much you're willing to spend on each click.
                  This allows you to make sure that you're not overspending on
                  your ads.
                </p>
              </div>
            </div> */}
          </div>
          <div className="our-service-ads-grid-btn">
            <div className="get-started-btn">
              <Link to={data?.get_started?.link}>
                <Button>
                  {data?.get_started?.text}
                  <img src={arrow} alt="icon" />
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="our-service-ads-img">
          <img src={data?.image?.url} alt="instagram" />
        </div>
      </div>
    </div>
  </div>
)
export default InstagramAds
