import React from "react"
import tiktok from "../../../src/images/Service/tiktok.svg"
import checkmark from "../../../src/images/Service/check.svg"
import arrow from "../../../src/images/Service/arrow.svg"
import { Button } from "react-bootstrap"
import { Link } from "gatsby"

import "./ourservice.css"

const TiktokAds = ({
  data
}) => (
  <div className="our-service-ads-gray-content" id="tiktokads">
    <div className="container">
      <div className="our-service-ads">
        <div className="our-service-ads-img">
          <img src={data?.image?.url} alt="tiktok" />
        </div>
        <div className="our-service-ads-text">
          <h4>{data?.title}</h4>
          <div className="our-service-ads-grid">

            {
              data?.service_point?.map((point) => {
                return (

                  <div className="our-service-ads-grid-box">
                    <img src={checkmark} alt="checkmark" />
                    <div className="our-service-ads-item-text">
                      <p>
                        <span>{point?.title}:</span>
                        {point?.description}
                      </p>
                    </div>
                  </div>
                )
              })
            }
            {/* <div className="our-service-ads-grid-box">
              <img src={checkmark} alt="checkmark" />
              <div className="our-service-ads-item-text">
                <p>
                  <span>Reach a young audience:</span> TikTok is a platform that
                  is used mostly by younger people. If your target market is
                  this age group, then TikTok Ads could be a good option for
                  you.
                </p>
              </div>
            </div>
            <div className="our-service-ads-grid-box">
              <img src={checkmark} alt="checkmark" />
              <div className="our-service-ads-item-text">
                <p>
                  <span>Increased engagement:</span> TikTok users are highly
                  engaged with the content on the platform. This means that
                  they're more likely to watch your ad all the way through and
                  take action after seeing it.
                </p>
              </div>
            </div>
            <div className="our-service-ads-grid-box">
              <img src={checkmark} alt="checkmark" />
              <div className="our-service-ads-item-text">
                <p>
                  <span> Fun and creative:</span> TikTok is all about creativity
                  and having fun. This means that your ads can be creative and
                  attention-grabbing, which can help you get more people to
                  notice them.
                </p>
              </div>
            </div> */}
          </div>
          <div className="our-service-ads-grid-btn">
            <div className="get-started-btn">
              <Link to={data?.get_started?.link}>
                <Button>
                  {data?.get_started?.text}
                  <img src={arrow} alt="icon" />
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
export default TiktokAds
