import React from "react"
import Layout from "../components/Layout/layout"
import GrowYourBusiness from "../components/Home/Growbusinesslight"
import MarketeqDifferent from "../components/Home/Marketeqdifferent"
import OurClients from "../components/Home/Ourclients"
import FacebookAds from "../components/Ourservice/Facebookads"
import GoogleAds from "../components/Ourservice/Googleads"
import InstagramAds from "../components/Ourservice/Instagramads"
import LinkedInAds from "../components/Ourservice/Linkedinads"
import MarketingAutomation from "../components/Ourservice/Marketingautomation"
import SaleFunnel from "../components/Ourservice/Salefunnel"
import Seo from "../components/Ourservice/seo"
import ServiceBanner from "../components/Ourservice/Servicebanner"
import TiktokAds from "../components/Ourservice/Tiktokads"
import CustomWebDesign from "../components/Ourservice/Webdesign"

const OurServicePage = ({
  pageContext
}) => {

  const { navBar, footer, servicePage, homePage } = pageContext;
  console.log({
    servicePage,
    navBar,
    footer,
    homePage
  });

  return (
    <Layout
      navBar={navBar}
      footer={footer}
    >
      <ServiceBanner data={servicePage} />
      <GoogleAds data={servicePage?.google_ads} />
      <FacebookAds data={servicePage?.facebook_ads} />
      <InstagramAds data={servicePage?.instagram_ads} />
      <TiktokAds data={servicePage?.tiktok_ads} />
      <LinkedInAds data={servicePage?.linkedin_ads} />
      <SaleFunnel data={servicePage?.sales_funnel} />
      <MarketingAutomation data={servicePage?.marketing_automation} />
      <CustomWebDesign data={servicePage?.custom_web_design} />
      <Seo   data={servicePage?.seo} />
      <MarketeqDifferent data={homePage?.marketeq_different} />
      <OurClients data={homePage?.our_clients} />
      <GrowYourBusiness data={homePage?.our_clients} />
    </Layout>
  )
}
export default OurServicePage
