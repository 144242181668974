import React from "react"
import salesfunnel from "../../../src/images/Service/salesfunnel.svg"
import checkmark from "../../../src/images/Service/check.svg"
import arrow from "../../../src/images/Service/arrow.svg"
import { Button } from "react-bootstrap"
import { Link } from "gatsby"
import "./ourservice.css"

const SaleFunnel = ({ data }) => (
  <div className="our-service-ads-gray-content" id="customfunnels">
    <div className="container">
      <div className="our-service-ads">
        <div className="our-service-ads-img">
          <img src={data?.image?.url} alt="salesfunnel" />
        </div>
        <div className="our-service-ads-text">
          <h4>{data?.title}</h4>

          <div className="our-service-ads-grid">

            {
              data?.service_point?.map((point) => {
                return (

                  <div className="our-service-ads-grid-box">
                    <img src={checkmark} alt="checkmark" />
                    <div className="our-service-ads-item-text">
                      <p>
                        <span>{point?.title}:</span>
                        {point?.description}
                      </p>
                    </div>
                  </div>
                )
              })
            }

            {/* <div className="our-service-ads-grid-box">
              <img src={checkmark} alt="checkmark" />
              <div className="our-service-ads-item-text">
                <p>
                  <span>Increased reach:</span> With over 1.5 billion active
                  users, your potential customer base is significantly larger on
                  Facebook than any other platform.
                </p>
              </div>
            </div>
            <div className="our-service-ads-grid-box">
              <img src={checkmark} alt="checkmark" />
              <div className="our-service-ads-item-text">
                <p>
                  <span>Targeting options:</span> Google allows you to target
                  ads based on keywords, which ensures that your ad reaches the
                  right people. You can also use negative keywords to exclude
                  people who are not interested in your product or service.
                </p>
              </div>
            </div>
            <div className="our-service-ads-grid-box">
              <img src={checkmark} alt="checkmark" />
              <div className="our-service-ads-item-text">
                <p>
                  <span>Cost-effective:</span> Facebook Ads are very
                  cost-effective, especially when you compare them to other
                  platforms like Google AdWords. You can get your message in
                  front of a large number of people for a fraction of the cost.
                </p>
              </div>
            </div> */}
          </div>
          <div className="our-service-ads-grid-btn">
            <div className="get-started-btn">
              <Link to={data?.get_started?.link}>
                <Button>
                  {data?.get_started?.text}
                  <img src={arrow} alt="icon" />
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
export default SaleFunnel
