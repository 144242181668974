import React from "react"
import figma from "../../../src/images/Service/figma.svg"
import checkmark from "../../../src/images/Service/check.svg"
import arrow from "../../../src/images/Service/arrow.svg"
import { Button } from "react-bootstrap"
import { Link } from "gatsby"

import "./ourservice.css"

const CustomWebDesign = ({
  data
}) => (
  <div className="our-service-ads-gray-content" id="CustomWeb">
    <div className="container">
      <div className="our-service-ads">
        <div className="our-service-ads-img">
          <img src={data?.image?.url} alt="figma" />
        </div>
        <div className="our-service-ads-text">
          <h4>{data?.title}</h4>
          {/* <p>
            We design and build custom websites that are responsive,
            user-friendly, and look great on all devices.{" "}
          </p> */}
          <div className="our-service-ads-grid">
            {
              data?.service_point?.map((point) => {
                return (

                  <div className="our-service-ads-grid-box">
                    <img src={checkmark} alt="checkmark" />
                    <div className="our-service-ads-item-text">
                      <p>
                        <span>{point?.title}:</span>
                        {point?.description}
                      </p>
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div className="our-service-ads-grid-btn">
            <div className="get-started-btn">
              <Link to={data?.get_started?.link}>
                <Button>
                  {data?.get_started?.text}
                  <img src={arrow} alt="icon" />
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
export default CustomWebDesign
