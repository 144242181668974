import React from "react"
import "./ourservice.css"

const ServiceBanner = ({
  data
}) => (
  <div className="our-service-banner-content">
    <div className="container">
      <div className="our-service-banner">
        <span>{data?.banner_tag}</span>
        <h1 dangerouslySetInnerHTML={{ __html: data?.banner_title }}>

        </h1>
      </div>
    </div>
  </div>
)
export default ServiceBanner
